<template>
  <div class="firstDiv">
    <!-- <div class="swiper-button-prev" style="position:absolute;background-color:red; width:50px; height:50px;left: 220px;top: 400px" slot="button-next"></div> -->
    <!-- <div class="swiper-button-next" style="position:absolute;background-color:red; width:50px; height:50px;left: 1620px;top: 400px" slot="button-next"></div> -->

    <div class="centerDiv">
      <div class="thumb-example">
        <!-- 大轮播图 -->
        <swiper
          class="swiper gallery-top"
          :options="swiperOptionTop"
          ref="swiperTop"
        >
          <swiper-slide v-for="(item,index) in imglist" :key="index" :style="{'background':'url(' + item + ') no-repeat center / cover'}"></swiper-slide>
          <!--          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>-->
          <!--          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>-->
        </swiper>
        <!-- 小缩略图 -->
        <swiper
          class="swiper gallery-thumbs"
          :options="swiperOptionThumbs"
          ref="swiperThumbs"
        >
        <swiper-slide v-for="(item,index) in imglist" :key="index" :style="{'background':'url(' + item + ') no-repeat center / cover', }"></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
 
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
//import 'swiper/css/swiper.css'
import "swiper/swiper-bundle.css";

export default {
  name: "image",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    imglist: []
  },
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 8, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 8, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      console.log(this.$refs);
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
};
</script>
 
<style scoped>
.firstDiv {
  width: 100%;
  height: 300px;
  position: relative;
}

.centerDiv {
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  top: 0;
}

.thumb-example {
  height: 100%;
  background-color: transparent;
}

.swiper-slide {
  background-size: cover!important;;
  background-position: center;
}

.slide-1 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-2 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-3 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-4 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-5 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-6 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-7 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}
.slide-8 {
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Ff%2F57a42b9002e19.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673501698&t=74cf53b2a570d4896ee4a523ca27ddb7");
}

.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
</style>
 
 
 
 