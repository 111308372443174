<template>
    <div class="headertop">
        <div id="mapqq"></div>
        <gmap-map :center="centers"> </gmap-map>
        <div class="center">
            <div class="left">
                <img :src="logo" alt="" style="width: auto; height: 45px; margin-right: 20px" />
                <img src="@/assets/dingwei.png" alt="" @click="toPage('/information')" />
                <span class="title" @click="toPage('/information')">{{ $store.state.place }}</span>
                <span class="check" @click="checkDingwei">[切换/定位]</span>
            </div>
            <div class="right">
                <!-- <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" style="width: 200px" clearable
          @change="getGoodeList" v-model="query.searchValue">
        </el-input> -->

                <div class="paixu">
                    <div class="li" v-if="!backHome" @click="goBack()">
                        <i class="el-icon-s-promotion"></i>
                        <span style="font-size: 17px">返回首页</span>
                    </div>
                    <div class="li" v-if="backHome" :class="fenleiInv == 1 ? 'active' : ''" @click="changeSort('distance', 1)">
                        <i class="el-icon-s-promotion"></i>
                        <span style="font-size: 17px">距离</span>
                        <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'distance'"></i>
                        <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'distance'"></i>
                    </div>
                    <div class="li" v-if="backHome" :class="fenleiInv == 4 ? 'active' : ''" @click="changeSort('likeNum', 4)">
                        <i class="el-icon-thumb"></i>
                        <span style="font-size: 17px">点赞</span>
                        <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'likeNum'"></i>
                        <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'likeNum'"></i>
                    </div>
                    <div class="li" v-if="backHome" :class="fenleiInv == 2 ? 'active' : ''" @click="changeSort('collectNum', 2)">
                        <i class="el-icon-s-flag"></i>
                        <span style="font-size: 17px">收藏</span>
                        <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'collectNum'"></i>
                        <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'collectNum'"></i>
                    </div>
                    <div class="li" v-if="backHome" :class="fenleiInv == 5 ? 'active' : ''" @click="changeSort('comNum', 5)">
                        <i class="el-icon-s-comment"></i>
                        <span style="font-size: 17px">评论</span>
                        <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'comNum'"></i>
                        <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'comNum'"></i>
                    </div>

                    <div class="li" v-if="backHome" :class="fenleiInv == 3 ? 'active' : ''" @click="changeSort('days', 3)">
                        <i class="el-icon-s-custom"></i>
                        <span style="font-size: 17px">入驻时间</span>
                        <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'days'"></i>
                        <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'days'"></i>
                    </div>
                    <!-- <div class="li">
            <el-button type="text" :plain="query.sortField == 'collectNum' ? false : true" 
              @click="changeSort('collectNum')" icon="el-icon-s-flag">收藏人数
              <i class="el-icon-top el-icon--right" v-if="
                query.sortOrder == 'asc' && query.sortField == 'collectNum'
              "></i>
              <i class="el-icon-bottom el-icon--right" v-if="
                query.sortOrder == 'desc' && query.sortField == 'collectNum'
              "></i>
            </el-button>
          </div>
          <div class="li">
            <el-button type="text" :plain="query.sortField == 'days' ? false : true" 
              @click="changeSort('days')" icon="el-icon-s-custom">入驻时间
              <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'days'"></i>
              <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'days'"></i>
            </el-button>
          </div> -->
                </div>
                <!-- <div class="li" :class="Inv == 2 ? 'active' : ''" @click="changeInv(2)">
          <i class="iconfont">&#xe63d;</i>
          <span>购物</span>
        </div>
        <div class="li" :class="Inv == 1 ? 'active' : ''" @click="changeInv(1)">
          <i class="iconfont">&#xe606;</i>
          <span>服务</span>
        </div>

        <div class="li" :class="Inv == 3 ? 'active' : ''" @click="changeInv(3)">
          <i class="iconfont">&#xe628;</i>
          <span>信息</span>
        </div>
        <div
          class="li"
          :class="scInv == 1 ? 'active' : ''"
          @click="toPagess(`/informationsc?collectStatus=1`)"
        >
          <i class="iconfont">&#xe615;</i>
          <span>我的收藏</span>
        </div> -->
                <!-- <div class="li" @click="toPage('/login')">
          <span>登录/注册</span>
        </div> -->
                <div style="white-space: nowrap" class="li" :class="myInv == 1 ? 'active' : ''" @click="toMyinfo()">
                    <img src="@/assets/myicon.png" alt="" />
                    <span>个人中心</span>
                </div>
            </div>
        </div>

        <el-dialog title="" :visible.sync="dialogVisible" width="50%" :modal-append-to-body="false">
            <div class="right">
                <div class="table">
                    <el-button type="primary" style="margin-bottom: 10px" size="small" @click="toMap()">地图定位</el-button>
                    <el-table
                        :row-class-name="tableClass"
                        :data="tableData"
                        border
                        style="width: 100%"
                        :cell-style="{ textAlign: 'center' }"
                        :header-cell-style="{
                            textAlign: 'center',
                            backgroundColor: '#ea4335',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 400,
                        }"
                    >
                        <el-table-column prop="name" label="标签" width="80">
                            <template slot-scope="scope">
                                {{ scope.row.name ? scope.row.name : "暂无" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="linkName" label="联系人" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.linkName ? scope.row.linkName : "暂无" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="linkPhone" label="电话" width="140">
                            <template slot-scope="scope">
                                {{ scope.row.linkPhone ? scope.row.linkPhone : "暂无" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="detailAddress" label="地址"> </el-table-column>
                        <el-table-column fixed="right" label="操作" width="130">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="qiehuan(scope.row)">切换</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="fenye" style="margin-top: 10px">
                    <el-pagination background layout="prev, pager, next" @current-change="currentChange" :page-size="query.pageSize" :total="total"> </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { gmapApi } from "vue2-google-maps";
    import { TMap } from "@/initMap";
    import { inwaySetall } from "@/request/api.js";

    import { memberCollectAddresslist } from "@/request/api.js";
    export default {
        name: "headertop",
        data() {
            return {
                fenleiInv: -1,
                dialogVisible: false,
                myInv: 0,
                total: 0,
                lat: 0,
                tableData: [],
                lng: 0,
                query: {
                    pageSize: 15,
                    pageNum: 1,
                    longitude: "",
                    latitude: "",
                    searchValue: "",
                    classify: "",
                    sortField: "distance",
                    sortOrder: "asc",
                    fixType: "",
                    collectStatus: "",
                },
                scInv: 0,
                place: "定位中...",
                Inv: 2,
                logo: "",
                global: this.GLOBAL,
                backHome: false,
                tMap: null,
                asia: true,
            };
        },
        mounted() {},
        created() {
            this.geolocate();
            this.defaultSet();
            if (this.$route.name == "information") {
                this.backHome = true;
            } else {
                this.backHome = false;
            }
        },
        computed: {
            google: gmapApi, // 获取官方的OBject 使用官方API的时候可以用
            centers() {
                return {
                    lat: this.lat,
                    lng: this.lng,
                };
            },
        },
        methods: {
            defaultSet() {
                inwaySetall().then((res) => {
                    console.log(res);
                    this.logo = res.data.pcLogo;
                    document.title = res.data.webTitle;
                    let $favicon = document.querySelector('link[rel="icon"]');
                    if ($favicon !== null) {
                        $favicon.href = res.data.webFavicon;
                    } else {
                        $favicon = document.createElement("link");
                        $favicon.rel = "icon";
                        $favicon.href = res.data.webFavicon;
                        document.head.appendChild($favicon);
                    }
                });
            },
            checkDingwei() {
                this.getData();

                this.dialogVisible = true;
            },
            changeInv(type) {
                // if(this.Inv == type){
                //   return
                // }
                this.myInv = 0;
                this.scInv = 0;
                this.Inv = type;
                this.$store.commit("setType", this.Inv);

                // this.$emit('changeTop',this.Inv)
            },
            goBack() {
                this.$router.push({
                    path: "/information",
                });
            },
            toPage(url) {
                this.myInv = 0;
                this.scInv = 0;

                this.$router.push({
                    path: url,
                });
            },
            toPagess(url) {
                this.Inv = 0;
                this.scInv = 1;
                this.myInv = 0;
                this.$router.push({
                    path: url,
                });
            },
            changeSort(e, index) {
                this.fenleiInv = index;
                this.myInv = 0;
                if (this.query.sortField == e) {
                    if (this.query.sortOrder == "asc") {
                        this.query.sortOrder = "desc";
                    } else {
                        this.query.sortOrder = "asc";
                    }
                } else {
                    this.query.sortField = e;
                }
                this.$emit("changeSort", e);
            },
            toMyinfo() {
                let token = localStorage.getItem("MemberAuthorization") ? localStorage.getItem("MemberAuthorization") : "";

                if (token) {
                    this.Inv = 0;
                    this.myInv = 1;
                    this.scInv = 0;
                    this.fenleiInv = -1;

                    this.$router.push({
                        path: "/informationmy",
                    });
                } else {
                    this.$router.push({
                        path: `/login`,
                    });
                }
            },
            toMap() {
                if (this.asia) {
                    this.dialogVisible = false;
                    this.$router.push({
                        path: `/checkaddresstx`,
                    });
                } else {
                    this.dialogVisible = false;
                    this.$router.push({
                        path: `/checkaddress`,
                    });
                }
            },
            geolocate() {
                this.$api.commonasia().then((res) => {
                    this.asia = res.data.asia;
                    if (this.asia) {
                        this.$api.commonipInfo().then((res) => {
                            console.log(res);
                            this.lat = res.data.location.lat;
                            this.lng = res.data.location.lng;
                            let data = {
                                lat: this.lat,
                                lng: this.lng,
                            };
                            this.$store.commit("setlatAndlng", data);

                            let query = {
                                location: this.lat + "," + this.lng,
                            };
                            this.$api.commonlocation(query).then((res) => {
                                this.place = res.data.formatted_addresses.recommend;
                                this.$store.commit("setPlace", this.place);
                            });
                        });
                    } else {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.lat = position.coords.latitude;
                            this.lng = position.coords.longitude;
                            let data = {
                                lat: this.lat,
                                lng: this.lng,
                            };
                            this.$store.commit("setlatAndlng", data);

                            console.log(position);

                            setTimeout(() => {
                                this.pointToAddress(this.lat, this.lng);
                            }, 100);
                        });
                    }
                });
            },
            tableClass({ row, rowIndex }) {
                if (rowIndex % 2 == 1) {
                    //奇数行，序号不能被2整除
                    return "even-row";
                } else {
                    return "";
                }
            },
            qiehuan(e) {
                let data = {
                    lat: e.latitude,
                    lng: e.longitude,
                };
                let initData = {
                    latitude: e.latitude,
                    longitude: e.longitude,
                    detailAddress: e.name,
                };
                this.dialogVisible = false;
                this.global.checkQiehuan = true;
                console.log(this.global);

                this.$store.commit("setlatAndlng", data);
                this.$store.commit("setPlace", e.name);
            },
            currentChange(e) {
                this.query.pageNum = e;
                this.getData();
            },
            pointToAddress(lat, lng) {
                // 实例化Geocoder服务用于解析地址
                var geocoder = new this.google.maps.Geocoder();
                var that = this;
                // 地理反解析
                geocoder.geocode({ location: new this.google.maps.LatLng(lat, lng) }, function geoResults(results, status) {
                    console.log(results);
                    that.place = results[0].formatted_address;
                    that.$store.commit("setPlace", that.place);
                });
            },
            getData() {
                memberCollectAddresslist(this.query).then((res) => {
                    console.log("收藏的地址");
                    console.log(res);
                    this.tableData = res.rows;
                    this.total = res.total;
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .paixu {
        display: flex;
        align-items: center;

        .li {
            display: flex;
            align-items: center;
            margin-left: 20px;
            font-size: 15px;
            cursor: pointer;
            color: #757474;
            position: relative;
            padding-right: 15px;
            white-space: nowrap;

            i {
                font-size: 20px;
                margin-right: 5px;
            }

            .el-icon--right {
                position: absolute;
                right: -15px;
                z-index: 10;
                top: 50%;
                transform: translateY(-50%);
            }

            img {
                width: 18px;
                margin-left: 4px;
            }
        }

        .active {
            color: #fff;
        }
    }

    .headertop {
        height: 70px;
        background: #1d1c1c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: sticky;
        top: 0;

        z-index: 100;
    }

    .kefuDia {
        .top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 50px;
                margin-right: 10px;
                border-radius: 10px;
            }

            div {
                margin-bottom: 4px;
            }
        }

        .num {
            margin-top: 5px;
        }
    }

    .center {
        width: 70%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconfont {
            font-size: 20px;
            margin-right: 5px;
            display: inline-block;
        }

        .right {
            display: flex;
            align-items: center;

            .li {
                display: flex;
                align-items: center;
                margin-left: 40px;
                cursor: pointer;
                color: #ff6464;
                letter-spacing: 1.5px;
            }

            img {
                width: 25px;
                margin-right: 5px;
            }

            .active {
                color: #fff !important;
            }
        }

        .left {
            display: flex;
            align-items: center;
            width: 50%;

            .title {
                font-weight: bold;
                display: block;
                white-space: nowrap;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
            }

            .li {
                display: flex;
                align-items: center;
                margin-right: 35px;
                color: #fff;
            }

            .active {
                .iconfont {
                    color: #dd0909;
                }
            }

            .check {
                font-size: 13px;
                margin-left: 4px;
                color: #0a96e9;
                margin-right: 30px;
            }

            img {
                width: 25px;
                margin-right: 10px;
            }
        }
    }
</style>
