<template>
  <div>
    <div style="width:100%;height:70px"></div>
    <div class="lastBox" v-html="footer">
    </div>
  </div>
</template>

<script>
import {
  memberUserinfo,
} from "@/request/api.js";
import { getmemberUserinfo, memberUserupdateInfo } from "@/request/api.js";

export default {
  name: "userdata",
  data() {
    return {
      footer:localStorage.getItem('footer')
    };
  },
  created() {
  },
  methods: {
 
  },
};
</script>

<style lang="scss" scoped>

</style>
