<template>
  <div>
    <headertop></headertop>
    <div class="goodsInfo">
      <div class="left">
        <!-- <el-carousel :interval="4000" arrow="always">
          <el-carousel-item v-for="item in 1" :key="item">
            <img class="bannerImg" :src="goodsInfo.infoPic" alt="" />
          </el-carousel-item>
        </el-carousel> -->
        <hasswiper :imglist="[goodsInfo.infoPic]"></hasswiper>
      </div>

      <div class="right">
        <div class="listA">
          <div class="ls">
            <div class="title">
              <span class="name">{{ goodsInfo.infoTitle }}</span>
              <div style="display: flex;">
                <span class="desc" @click.stop="dianzan()">
                  【{{goodsInfo.likeNum}}人点赞】
                  <img src="@/assets/dianzan.png" alt="" v-if="goodsInfo.likeStatus == 0">
                  <img src="@/assets/dianzan-a.png" alt="" v-else>
                </span>
                <span class="desc" @click.stop="shoucang()">【{{ goodsInfo.collectNum }}人收藏】
                  <img src="@/assets/shoucang.png" alt="" v-if="goodsInfo.collectStatus == 0" />
                  <img src="@/assets/shoucang_a.png" alt="" v-if="goodsInfo.collectStatus == 1" />
                </span>
              </div>
            </div>
            <div class="smdesc">
              <span>资讯编码：{{ goodsInfo.infoCode }}</span>
            </div>
            <div class="smdesc">
              <span>发布主体：{{ goodsInfo.infoMainName }}</span>
              <img src="@/assets/link.png" alt="" style="margin-left: 8px" @click="toLink"
                v-if="goodsInfo.memberHomePage" />
            </div>
            <div class="smdesc">
              <span>会员类型：{{ goodsInfo.infoTypeDesc}}</span>
            </div>

            <div class="smdesc">
              <span>入驻时间：{{ goodsInfo.days }} 天</span>
            </div>
            <div class="addCar">
              <div class="rights">
                <div class="flexboxs" v-for="(item, index) in goodsInfo.links" :key="index">
                  <img :src="item.icon" style="width: 25px" alt="" />
                  <span style="margin-right: 5px">{{ item.name }}：</span>
                  <span>{{ item.content }}</span>
                </div>
              </div>
              <!-- <div class="rights btns" @click="toGooleMap">
                <i class="iconfont">&#xe63f;</i>
                <span>导航</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tabCheck">
      <el-tabs v-model="activeName">
        <el-tab-pane label="详情" name="1">
          <div class="infoData">
            <div class="title">详情</div>
            <div class="desc" v-html="goodsInfo.infoDetail"></div>
            <div class="textRight" @click="back">
              <i class="iconfont">&#xe6ff;</i>
              返回
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="评论" name="2">
          <div class="sendBox">
            <input type="text" placeholder="请输入评论内容" v-model="form.comment" />
            <div class="btnas" @click="submit">提交</div>
          </div>

          <div class="pinglunlist">
            <div class="list" v-for="(item, index) in goodsInfo.comments" :key="index">
              <img :src="item.headUrl" alt="" />
              <div>
                <div class="name">
                  {{ item.nickName }}
                  <span class="small">{{ item.createTime }}</span>
                </div>
                <div class="desc">
                  {{ item.comment }}
                </div>
              </div>
            </div>
          </div>
          <div class="textRight" @click="back">
            <i class="iconfont">&#xe6ff;</i>
            返回
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <bottombox></bottombox>
  </div>
</template>

<script>
  import headertop from "./components/header.vue";

  import {
    neighborInfounCollect,
    neighborInfocollect,
    neighborInfodetail,
    neighborInfocomment,
    neighborInfolike,
    neighborInfounLike
  } from "@/request/api.js";
  import bottombox from "./components/bottombox.vue";

  import hasswiper from "../../components/hasswiper.vue";
  export default {
    name: "home",
    components: { hasswiper, bottombox, headertop },
    data() {
      return {
        id: "",
        goodsInfo: "",
        num: 1,
        seemore: false,
        activeName: "1",
        form: {
          comment: "",
          infoId: "",
        },
      };
    },
    created() {
      this.form.infoId = this.id = this.$route.query.id;
      this.getData();
    },
    mounted() { },

    methods: {
      toLink() {
        const link = this.goodsInfo.memberHomePage;
        window.location.href = link;
      },
      submit() {
        if (this.form.comment == "") {
          return this.$message.error("请输入评论信息");
        }

        neighborInfocomment(this.form).then((res) => {
          this.$message.success("评论成功");
          this.getData();
          this.form.comment = "";
        });
      },
      back() {
        this.$router.back();
      },
      shoucang() {
        if (this.goodsInfo.collectStatus == 0) {
          neighborInfocollect({ infoId: this.goodsInfo.infoId }).then((res) => {
            this.$message.success("收藏成功");

            this.getData();
          });
        } else {
          neighborInfounCollect({ infoId: this.goodsInfo.infoId }).then((res) => {
            this.$message.success("取消成功");

            this.getData();
          });
        }
      },
      dianzan() {
        if (this.goodsInfo.likeStatus == 0) {
          neighborInfolike({infoId: this.goodsInfo.infoId}).then(res => {
            this.$message.success("点赞成功");
            this.getData();
          })
        }else {
          neighborInfounLike({infoId: this.goodsInfo.infoId}).then(res => {
            this.$message.success("取消成功");
            this.getData();
          })
        }
      },
      getData() {
        neighborInfodetail({ infoId: this.id }).then((res) => {
          this.goodsInfo = res.data;
        });
      },
      toGooleMap() {
        let form = this.$store.state.place;
        let lat = this.goodsInfo.latitude;
        let lng = this.goodsInfo.longitude;
        window.open(`http://www.google.com/maps/dir/${form}/${lat},${lng}`);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pinglunlist {
    width: 100%;
    margin: 20px auto;

    .list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .name {
        span {
          font-size: 12px;
          color: #8f8f8f;
          margin-left: 5px;
        }
      }

      .desc {
        margin-top: 5px;
        font-size: 28rpx;
      }
    }
  }

  .tabCheck {
    width: 70%;
    margin: 0 auto;
  }

  .sendBox {
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;

    input {
      padding: 10px 15px;
      background-color: #fff;
      border-radius: 5px;
      width: 90%;
      font-size: 14px;
      border: 1px solid #ccc;
      outline: none;
    }

    .btnas {
      margin-left: 15px;
      width: 100px;
      height: 39px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #fc7474;
      cursor: pointer;
    }
  }

  .infoData {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;

    .title {
      color: #ff552e;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .desc {
      line-height: 30px;
      letter-spacing: 1px;
    }

    .seemore {
      text-align: center;
      display: block;
      font-size: 16px;
      color: #9b9797;
      cursor: pointer;

      .iconfont {
        display: inline-block;
        transform: rotate(180deg);
      }

      .up {
        transform: rotate(0);
      }
    }
  }

  .textRight {
    text-align: right;
    cursor: pointer;
    color: #ff552e;
  }

  .flexboxs {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    img {
      margin-right: 10px;
    }
  }

  .goodsInfo {
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-bottom: 20px;
    // border-bottom: 2px dashed #ccc;

    .left {
      width: 35%;

      .bannerImg {
        width: 100%;
      }
    }

    .right {
      width: 63%;
      position: relative;

      .listA {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        cursor: pointer;
        height: 100%;
      }

      .ls {
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        position: relative;

        .goodsImg {
          position: absolute;
          left: 10px;
          width: 180px;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
          }

          .desc {
            font-size: 14px;
            color: #525252;
            display: flex;
            align-items: center;
            margin-left: 15px;

            img {
              width: 20px;
            }
          }
        }

        .addCar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          width: 100%;
          cursor: pointer;

          .btns {
            background: #fc7474;
            padding: 6px 20px;
            border-radius: 5px;
            color: #fff;
          }

          .iconfont {
            margin-right: 5px;
            font-size: 20px;
          }
        }

        .guige {
          margin-top: 10px;
          font-size: 14px;
          color: #a9a7a7;

          .gui {
            background: #e9e9e9;
            margin-left: 10px;
            padding: 6px 18px;
            border-radius: 5px;
            font-size: 13px;
          }
        }

        .price {
          margin-top: 20px;

          .red {
            color: red;
            font-size: 25px;
            font-weight: bold;
            margin-right: 4px;
          }

          .num {
            font-size: 13px;
            color: #a9a7a7;
            margin-left: 5px;
          }
        }

        .smdesc {
          margin-top: 10px;

          span {
            font-size: 15px;
            color: #6c6c6c;
          }
        }
      }
    }
  }
</style>